import React from 'react'

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';

import { LocaleTypes } from '../data/locales';

import SecurityPolicyHero from '../components/SecurityPolicy/SecurityPolicyHero/SecurityPolicyHero'


interface securityPolicyProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const securityPolicy: React.FC<securityPolicyProps> = ({ pathContext, location}) => {

  const content = () => (
    <>
      <SEO title="Politica de privacidad" />

      <SecurityPolicyHero />

      <Footer theme="gray" />

    </>
  );

 return <IndexLayout render={content} locale={pathContext.localeCode} location={location} />
}

export default securityPolicy
