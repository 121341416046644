import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const SecurityPolicyHero: React.FC = ({children}) => (
  <section className="security-advice-hero">
  <div className="container">
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <h1>
          Política de Seguridad de la Información
        </h1>
        <div>
        Kushki dentro de sus objetivos busca brindar un servicio integral, seguro y fácil de usar. 
        <br /><br />
        Con el fin de cumplir este objetivo y brindar seguridad a la información de nuestros clientes y usuarios finales todas las filiales se comprometen a registrar todos los eventos realizados dentro de la plataforma, ya sean ejecutados por usuarios finales como por el personal operativo de la empresa.
        <br /><br />
        Además, se compromete a gestionar de manera adecuada los usuarios y sus accesos dentro de la infraestructura tecnológica de Kushki Pagos, brindar calidad y escalabilidad en sus productos y mantener un enfoque en la mejora continua para cumplir dicho objetivo a largo plazo. 
        <br /><br />
        Para ello, se establecerán una serie de procesos, así como un conjunto de normas para el manejo de usuarios y accesos dentro de su infraestructura. Estos procesos y normas se alinearán con la norma ISO 27001 y su cumplimiento es obligatorio para apoyar al Sistema de Gestión de Seguridad de la Información (SGSI). 
        <br /><br />Kushki realiza esta declaración de compromiso, justificada en que para la empresa es muy importante contar con políticas de seguridad ya que son ellas quienes guiarán el comportamiento personal y profesional de los colaboradores, proveedores o terceros sobre la información obtenida, generada o procesada por la empresa, así mismo las políticas permitirán que la empresa trabaje bajo las mejores prácticas de seguridad y cumpla con los requisitos legales a los cuales esté obligada a cumplir. 
        <br /><br />Debido a la importancia y sensibilidad de la información, se incluye el sistema de seguridad de la información dentro del sistema de gestión de la empresa de tal forma que le permita generar la mejora continua del sistema de seguridad, basados en la gestión de riesgos y continuidad en Kushki.
        </div>
        
      </div>
    </div>
  </div>
</section>
)

export default SecurityPolicyHero;
